@use '@angular/material' as mat;


// We use breakbpoints in vars also (so we must first gett bootsratp utils
@import './styles-vars.scss';


//@import '~bootstrap/scss/bootstrap';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
//@import '~bootstrap/scss/root';
//@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
//@import '~bootstrap/scss/tables';
//@import '~bootstrap/scss/forms';
//@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
//@import '~bootstrap/scss/dropdown';
//@import '~bootstrap/scss/button-group';
//@import '~bootstrap/scss/input-group';
//@import '~bootstrap/scss/custom-forms';
//@import '~bootstrap/scss/nav';
//@import '~bootstrap/scss/navbar';
//@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
//@import '~bootstrap/scss/pagination';
// @import '~bootstrap/scss/badge';
//@import '~bootstrap/scss/jumbotron';
//@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
// @import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
//@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

// ICONS
@import "~@fortawesome/fontawesome-free/scss/variables";

html {
  overflow-y: auto;
}
// due to the above: we need to fix the Angular/Bootstrap modal display using this rule below
body.modal-open{
  padding-right: 0px !important;
}


.nav-pills .nav-link {
  @extend %nav-link-spacing;
}


.nav-pills .nav-link, .page-link {
  @extend %nav-link-behaviour;
}

.btn-link {
  // color: var(--primary) !important;
}


.nav-pills .nav-link:hover, .page-link:hover {
  @extend %nav-link-hover;
}

/**
* In theroy, if we do session tracking correctly, it wont matter if we open up another :window-inactive
*/
a[target="_blank"] {
  // &:before {
  //   font-family: "Font Awesome 5 Free";
  //   font-weight: 600;
  //   font-size: small;
  //   content: fa-content($fa-var-external-link-alt);
  //   margin-right: $table-cell-padding-sm
  // }
  // color: var(--primary);
}

.page-item.disabled .page-link {
  // color: #930027;
  // pointer-events: none;
  // cursor: auto;
  // background-color: #fff;
  // border-color: #930027;
  opacity: 0.6;
}

/*google autocomplete*/

.pac-container,
.dropdown-menu {
  z-index: 1251 !important;
}

.snack-white {
  .mat-simple-snackbar-action {
    color: white !important;
  }
}

.form-control {
  // Placeholder
  &::placeholder {
   // color: var(--primary);
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 0.5 !important;
  }

  &:disabled::placeholder {
    opacity: 0 !important;
  }
}

// Leaner FormGroup margins require leaner form-group Label margins (no: form-group margins should be separate)
.form-group {
  &.row {
    // already has a margin bottom
  }
  label {
    // margin-bottom: 0.25rem;
    margin-bottom: 0rem;
  }
}

.table-fixed {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-up($breakpoint) {
        table-layout: fixed
      }
    }
  }
}

@media screen {
  .table-responsive-screen {
    @each $breakpoint in map-keys($grid-breakpoints) {
      $next: breakpoint-next($breakpoint, $grid-breakpoints);
      $infix: breakpoint-infix($next, $grid-breakpoints);

      &#{$infix} {
        @include media-breakpoint-down($breakpoint) {
          display: block;
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
          -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057

          // Prevent double border on horizontal scroll due to use of `display: block;`
          > .table-bordered {
            border: 0;
          }
        }
      }
    }
  }
}

@media print {
  thead, tfoot {
    display: table-row-group;
  }

  tr td {
    page-break-inside: avoid;
  }

  .table-responsive-screen {
    @each $breakpoint in map-keys($grid-breakpoints) {
      $next: breakpoint-next($breakpoint, $grid-breakpoints);
      $infix: breakpoint-infix($next, $grid-breakpoints);

      &#{$infix} {
        @include media-breakpoint-down($breakpoint) {
          display: block;
          min-width: 100%;

          // Prevent double border on horizontal scroll due to use of `display: block;`
          > .table-bordered {
            border: 0;
          }
        }
      }
    }
  }

  .navbar {
    display: none;
  }
  body {
    line-height: 1;
    background-image: none;
  }

  .container {
    max-width: 100% !important;
    width: 100% !important;
  }
}

@mixin table-row-light-variant($state, $background) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.
  .table-light-#{$state} {
    &,
    > th,
    > td {
      background-color: lighten($background, 10%);
    }
  }

  // Hover states for `.table-hover`
  // Note: this is not available for cells or rows within `thead` or `tfoot`.
  .table-hover {
    $hover-background: darken($background, 5%);

    .table-#{$state} {
      @include hover {
        background-color: $hover-background;

        > td,
        > th {
          background-color: $hover-background;
        }
      }
    }
  }
}

@each $color, $value in $theme-colors {
  @include table-row-light-variant($color, theme-color-level($color, -9));
}

@include table-row-light-variant(active, $table-active-bg);

.dropdown-menu {
  overflow-y: auto;
  max-height: 400px;
  .dropdown-item {
    cursor: pointer;
  }
}

.tenant-logo {
  max-height: 70px;
  max-width: 200px;
}


@import '@angular/material/theming';

$cdk-z-index-overlay-container: 1100;
$cdk-z-index-overlay: 1100;

.mat-icon {
  vertical-align: bottom;
}

.cdk-overlay-pane {
  max-width: 100vw !important;
  max-height: 100vh !important;
}

$iopen-typography: mat.define-typography-config(
  $font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
);
@include mat.core($iopen-typography);

.mat-list-item.disabled{
  cursor: not-allowed !important;
  opacity: 0.5;
  text-decoration: none;
}

.cdk-overlay-container {
  z-index: 1100;
}

button.mat-menu-item {
  outline:none;
}
/*
@font-face {
  font-family: 'Eurostile';
  src: url('./woff/EurostileBold.woff2') format('woff2'),
      url('./woff/EurostileBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Eurostile-Demi';
  src: url('./woff/Eurostile-Demi.woff2') format('woff2'),
      url('./woff/Eurostile-Demi.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

*/

// Base Style Imports
@import './styles/base/utility.scss';

/**
Fade the Logo in, as there is a delay before the tenant data is loaded
note: this can be removed once we solve the timing issue of loading tenant data
**/


// Hide Job Search - Welcome Title by Default (non-public) Users
abi-jobs-search {
  .welcome-title {
    display: none;
  }
}

// Remove Padding on Menu Panels (looks odd with accordions and extra spacing isnt needed here)
div.mat-menu-panel {
  .mat-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

/**
  ADDRESS STYLES
**/
a.address {
  &.good {
    color: var(--success);
  }
  &.partial {
    color: var(--warning);
  }
  &.poor {
    color: var(--danger);
  }
}

.line-breaker {
  white-space: pre-line;
}

// ANGULAR CALENDAR
@import "../node_modules/angular-calendar/css/angular-calendar.css";

// TENANT OVERRIDES
// AUTHED STYLE OVERRIDES GO HERE
// *public styles go into separate sheets and are programmatically loaded when routes are detected
// *tenant names are applied as class names under abi-root

// SNOMASTER
abi-root > .snomaster {
  abi-machine-categories {
    .machine-field2 {
      order: 10;
    }
    .machine-field3 {
      order: 11;
    }
    .machine-field4 {
      order: 12;
    }
  }
}

// JOHNSON
abi-root > .johnson,
abi-root > .johnson-demo {
  abi-machine-categories {
    .machine-branchid {
      order: 13;
    }
    .machine-field2 {
      order: 10;
    }
    .machine-field3 {
      order: 11;
    }
    .machine-field4 {
      order: 12;
    }
  }
}

// KWIKOT
abi-root > .elx, abi-root > .elx-demo {
  abi-machine-categories {
    .machine-serial {
      order: 1;
    }
    .machine-field3 {
      order: 2;
    }
    .machine-field4 {
      order: 3;
    }
    .machine-dateofpurchase {
      order: 4;
    }
    // .machine-branchid{
    //   order: 5;
    // }
    .machine-field1 {
      order: 5;
    }
    .machine-field5 {
      order: 6;
    }
    .machine-field2 {
      order: 7;
    }
    .machine-attachment {
      order: 8;
    }
  }
}

input.form-control, select.form-control {
  padding-left: $input-padding;
  &:not(.custom-select) {
    padding-right: $input-padding;// we need the padding provided in the custom-select
  }
}
// Dont show 'caret' on disabled custom-select
.custom-select:disabled {
  background-image: none;
  padding-right: $input-padding;// we need the padding provided in the custom-select
  // padding-right: ;
}


table {
  .table-nested {
    margin-bottom: 0;
    font-size: 0.8rem;
    thead {
      th {
        font-weight: normal;
      }
    }
  }
}


// GENERAL EXTENSIONS
.w-md-30 {
  @include media-breakpoint-up(md) {
    width:30% !important;
  }
}

// TODO: rework 'text' prefix with a 'disabled' state support
.text-danger.disabled {
  // color: var(--gray-dark);
  opacity: 0.5;
}


table {
  a {
    cursor: pointer;
  }
}



// Experimental Modal styles
.modal-fullscreen{
  width: 80vw;
  max-width: none;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @include media-breakpoint-down(lg) {
    width: 100vw;
    margin: 0;
  }
}

// TABLES and COLUMNS
.w-32px {
  width: 32px;
  // min-width: 32px;
}
.w-50px {
  width: 50px;
  // min-width: 50px;
}
.w-80px {
  // width: 1%;
  width: 100px;
}
.w-100px {
  // width: 1%;
  width: 100px;
}
.w-150px {
  // width: 1%;
  width: 150px;
}
.w-200px {
  // width: 1%;
  width: 200px;
}


// Bootstrap TOOLTIP Customerisations
.tooltip {
  &.packed-left {
    .tooltip-inner {
      // max-width: 200px;
      // padding: 0.25rem 0.5rem;
      // color: #fff;
      // text-align: center;
      // background-color: #000;
      // border-radius: 0.25rem;
      text-align: left;
      max-width: fit-content;
    }
  }
}

// DatePicker - DateRange
.ngb-dp-weekdays .ngb-dp-weekday {
  color: var(--primary);
}
.ngb-dp-day.disabled {
  // color: var(--gray);
  opacity: 0.5;
}
// Date Select - Custom Day Tempaltes
.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  &.outside {
    // color: var(--gray);
    opacity: 0.66;
  }
}

.custom-day.focused {
  background-color: #e6e6e6;
}

.custom-day.range,
.custom-day:hover {
  background-color: var(--primary);
  color: white;
}

.custom-day.faded {
  background-color: #93002783;
}

// LISTS
dl {
  &.dl-sm {
    dt {
      // font-size: 0.8rem;
    }
    dd {
      // font-size: 0.8rem;
      margin-bottom: 0;
    }
  }
  dt {
    margin-bottom: $input-padding;
  }
  dd {
    margin-bottom: $input-padding;
  }
}


// Page Header and Navigation
.back-and-title {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  abi-back-button {
    @extend %nav-link-spacing;
    button:hover {
      @extend %nav-link-hover;
    }
  }
  h1, h2, h3, h4, h5 {
    margin-bottom: 0;
    // margin: 0;
  }
}
fieldset {
  border: var(--dr-border-width) solid var(--dr-border-color);
  border-radius: var(--dr-border-radius);
  box-shadow: var(--dr-box-shadow-sm);
  legend {
    width: initial;
    margin: 0;
    padding: 0 0.5rem;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6;
  }
}
